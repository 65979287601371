
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get } from '@/services/http';
import { Redirect } from '@shopify/app-bridge/actions';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';

@Component
export default class UpgradeVersion extends Vue {
    @Prop({default: false})
    public needUpgrade!: boolean;
    public installUrl: string = '';
    @Prop({default: ''})
    public shopName!: string;
    public showLoading: boolean = false;
    @Prop({default: null})
    public app!: any;
    public showDialog: boolean = true;

    public async upgradeVersion() {
      if (this.needUpgrade) {
        this.showLoading = true;
        const res: any = await get('/shopify/install?shop=' + this.shopName);
        this.installUrl = res.install_url;
        this.showLoading = false;
      }
      if (this.installUrl) {
          this.showLoading = true;
          // If your app is embedded inside Shopify, use App Bridge to redirect
          if (isShopifyEmbedded()) {
            Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, this.installUrl);
            // Otherwise, redirect using the `window` object
          } else {
            window.location.assign(this.installUrl);
          }
          this.installUrl = '';
          this.needUpgrade = false;
      }
    }
    
    public cancelUpgrade() {
      // Close the upgrade dialog
      this.$emit('update:needUpgrade', false);
      
      // Navigate to homepage and reload the app
      if (isShopifyEmbedded()) {
        // For embedded apps, use App Bridge to redirect to root path
        Redirect.create(this.app).dispatch(Redirect.Action.APP, '/');
      } else {
        // For standalone apps, use router and then reload
        this.$router.push('/');
      }
    }
}
